import { annotate } from 'rough-notation'

export default class extends HTMLElement {
  connectedCallback () {
    this.annotation = annotate(this, {
      type: this.dataset.type || 'underline',
      padding: parseInt(this.dataset.padding),
      animate: this.dataset.animate !== 'false',
      color: this.dataset.color || '#037fd8',
      iterations: parseInt(this.dataset.iterations || 1),
      brackets: this.dataset.brackets || ['left', 'right'],
      strokeWidth: this.dataset.stroke || '1'
    })

    if (this.dataset.onAppear) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.annotation.show()

            observer.unobserve(this)
          }
        })
      })
      observer.observe(this)
    } else {
      this.annotation.show()
    }
  }
}
