export default class extends HTMLElement {
  connectedCallback () {
  }

  load () {
    window.Tawk_API ||= {}
    window.Tawk_LoadStart = new Date()

    if (this.dataset.email) {
      window.Tawk_API.visitor = {
        name: this.dataset.name,
        email: this.dataset.email
      }
    }

    window.Tawk_API.onLoad = () => {
      window['tawk-widget'].remove()

      const elem = document.querySelector('.widget-visible')

      elem.classList.add('z-10', 'absolute')
      elem.id = 'tawk-widget'
      elem.dataset.turboPermanent = true
    }

    const s0 = document.getElementsByTagName('script')[0]
    const s1 = document.createElement('script')

    s1.async = true
    s1.src = 'https://embed.tawk.to/66b794fa0cca4f8a7a744c6d/1i4uhrtb7'
    s1.setAttribute('crossorigin', '*')
    s0.parentNode.insertBefore(s1, s0)
  }
}
