import { actionable } from '@github/catalyst/lib/actionable'

export default actionable(class extends HTMLElement {
  call (e) {
    e.preventDefault()

    this.dataset.ids.split(',').forEach((id) => {
      window[id].classList.toggle('hidden')
    })
  }
})
